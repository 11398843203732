import React from "react";
import styles from "./CondensedNavHeader.module.scss";
import TiiCKERLogo from "public/images/tiicker-logo.svg";
import classNames from "classnames";
import Link from "next/link";
import { useFetchUser } from "source/hooks";
import Hamburger from "../Hamburger/Hamburger";
import BellIcon from "public/icons/bellicon.svg";
import MagnifyingGlass from "public/icons/search-large.svg";
import { useFlags } from "source/hooks/useFlags";

type Props = {
  onSearchClick(): void;
  inverted?: boolean;
  notificationActive: boolean;
  setNotificationActive: (visible: boolean) => void;
  notificationDotVisible: boolean;
} & (
  | {
      isOpen: true;
      onClose(): void;
    }
  | {
      isOpen: false;
      onHamburgerClick(): void;
    }
);

const CondensedNavHeader = (props: Props) => {
  const userBundle = useFetchUser();
  const flags = useFlags();

  const { siteNotifications184605453 } = flags;

  return (
    <div className={styles.CondensedNavHeader__navbar}>
      <div className={styles.CondensedNavHeader__search}>
        <MagnifyingGlass
          className={classNames(styles.CondensedNavHeader__searchIcon, {
            [styles.CondensedNavHeader__searchIcon_Inverted]: props.inverted,
          })}
          onClick={() => props.onSearchClick()}
          data-cy="MobileNavSearch"
        />
      </div>
      <Link href={userBundle.user ? "/dashboard" : "/"}>
        <a>
          <TiiCKERLogo
            className={classNames(styles.CondensedNavHeader__logo, {
              [styles.CondensedNavHeader__logo_Inverted]: props.inverted,
            })}
            src="/images/tiicker-logo.svg"
            alt="TiiCKER Logo"
          />
        </a>
      </Link>
      <div className={styles.CondensedNavHeader__hamburger}>
        <Hamburger
          isOpen={props.isOpen}
          onClick={props.isOpen ? props.onClose : props.onHamburgerClick}
        />
        {userBundle.user && siteNotifications184605453 && (
          <div className={styles.CondensedNavHeader__bellIconNotificationAlert}>
            <BellIcon
              id="bellIcon"
              className={classNames(styles.CondensedNavHeader__bellIcon, {
                [styles.CondensedNavHeader__bellIcon_Inverted]: props.inverted,
              })}
              onClick={() => {
                props.setNotificationActive(!props.notificationActive);
              }}
              data-cy="NotificationBell__mobile"
            />
            <div
              className={classNames(
                props.notificationDotVisible
                  ? styles.CondensedNavHeader__notificationAlert
                  : styles.hidden
              )}
              id="notification-alert"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CondensedNavHeader;
