import styles from "./SidebarContainer.module.scss";
import classNames from "classnames";
import OpacityCover from "components/OpacityCover/OpacityCover";
import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

interface Props {
  isOpen?: boolean;
  children: React.ReactNode;
  onSidebarClose: (boolean) => void;
}

const SidebarContainer = (props: Props) => {
  const [closing, setClosing] = useState(false);

  const clickedOff = () => {
    setClosing(true);
    setTimeout(function () {
      setClosing(false);
      props.onSidebarClose(false);
    }, 250);
  };

  return (
    <>
      <OpacityCover
        onClick={clickedOff}
        visible={props.isOpen}
        closing={closing}
      />
      <div
        className={classNames(
          props.isOpen
            ? classNames(styles.sidebar, closing ? styles.closing : undefined)
            : styles.hidden
        )}
      >
        <div className={styles.closeContainer}>
          <CloseOutlined onClick={clickedOff} />
        </div>
        {props.children}
      </div>
    </>
  );
};

export default SidebarContainer;
