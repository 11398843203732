import classNames from "classnames";
import { uniqueId } from "lodash";
import Link from "next/link";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useCurrentWidth } from "source/hooks";
import Portal from "../Portal/Portal";
import styles from "./MiniMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/sharp-light-svg-icons";

interface MenuOption {
  text: string;
  onClick?(e: React.MouseEvent): void;
  href?: string;
}

type Props = {
  options: MenuOption[];
  activeClass?: string;
  position?: {
    x: "left" | "right";
  };
  manualOffset?: {
    x?: number;
    y?: number;
  };
  children: React.ReactNode;
};

const MiniMenu = (props: Props) => {
  const [isShowing, setIsShowing] = useState(false);
  const [menuCoordinates, setMenuCoordinates] = useState<
    { x: number; y: number } | undefined
  >();
  const childRef = useRef<HTMLElement>(null);
  const currentWidth = useCurrentWidth(1);
  const [menuId] = useState(uniqueId());

  useEffect(() => {
    const listenerEvent = (evt: Event) => {
      if (evt.target && (evt.target as HTMLElement).dataset) {
        const data = (evt.target as HTMLElement).dataset;

        if (data.miniMenuId === menuId) {
          return;
        }
      }

      setIsShowing(false);
    };

    isShowing
      ? document.addEventListener("click", listenerEvent)
      : document.removeEventListener("click", listenerEvent);

    return () => document.removeEventListener("click", listenerEvent);
  }, [isShowing]);

  useEffect(() => {
    if (!isShowing || !childRef.current) {
      return;
    }

    const coords = childRef.current.getBoundingClientRect();
    const leftPosition = coords.left + window.scrollX;
    const xPosition =
      props.position?.x === "right"
        ? currentWidth - leftPosition - coords.width
        : leftPosition;

    setMenuCoordinates({
      x: xPosition + (props.manualOffset?.x || 0),
      y:
        coords.top +
        window.scrollY +
        coords.height +
        (props.manualOffset?.y || 0),
    });
  }, [childRef.current, isShowing, currentWidth]);

  const children = React.Children.map(
    props.children,
    (child: React.ReactElement<any>) => {
      return React.cloneElement(child, {
        ref: childRef,
        ["data-mini-menu-id"]: menuId,
        className: classNames(
          child.props.className,
          isShowing && props.activeClass
        ),
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setIsShowing(true);
        },
      });
    }
  );

  return (
    <>
      {children}
      {isShowing && menuCoordinates !== undefined && (
        <Portal containerSelector="#menu-container">
          <div
            style={{
              top: menuCoordinates.y,
              ...(props.position?.x === "right"
                ? { right: menuCoordinates.x }
                : { left: menuCoordinates.x }),
            }}
            className={styles.menu}
          >
            {props.options.map((option) =>
              option.href ? (
                <Link href={option.href}>
                  <a
                    className={classNames(styles.menuItem, {
                      [styles.menuRightAlign]: props.position?.x === "right",
                    })}
                  >
                    {option.href === '/refer-a-friend' ? (
                      <>
                        <FontAwesomeIcon icon={faGift} style={{color: "#85bb65", marginRight: "5px", scale: ".85"}} />
                        {option.text}
                      </>) : (
                      <>
                        {option.text}
                      </>
                    )}
                  </a>
                </Link>
              ) : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsShowing(false);
                    option.onClick && option.onClick(e);
                  }}
                  className={classNames(styles.menuItem, {
                    [styles.menuRightAlign]: props.position?.x === "right",
                  })}
                >
                  {option.text}
                </a>
              )
            )}
          </div>
        </Portal>
      )}
    </>
  );
};

export default MiniMenu;
