import React from "react";
import { Message } from "react-hook-form";
import ErrorIcon from "/public/icons/warning.svg";
import styles from "./InputWrapper.module.scss";

export type FormWrapperProps = {
  name: string;
  required?: boolean;
  label?: string;
  error?: string | Message | null;
  className?: string;
  animateLabel?: boolean; // Add the animateLabel prop
};

type Props = {
  children: React.ReactNode;
} & FormWrapperProps;

const InputWrapper = (props: Props) => {
  return (
    <div className={props.className}>
      <div className={styles.labelAndError}>
        {/* Conditionally render the label */}
        {!props.animateLabel && <label htmlFor={props.name}>{props.label}</label>}
        {props.error && <span className={styles.error}>{props.error}</span>}
      </div>
      {props.children}

      {props.error && <ErrorIcon className={styles.errorIcon} />}
    </div>
  );
};

export default InputWrapper;
