import classNames from "classnames";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import styles from "./SearchSidebar.module.scss";

export type SearchInsightResult = {
  slug: string;
  title: string;
};

type Props = {
  results: SearchInsightResult[];
  isLoading?: boolean;
  closeSlider(): void;
  clearState: () => void;
};

const SidebarInsightResults = (props: Props) => {
  const [lastListSize, setLastListSize] = useState(2);

  useEffect(() => {
    setLastListSize(props.results.length);
  }, [props.results.length]);

  if (props.isLoading) {
    return (
      <>
        {Array.from({ length: lastListSize }).map((_, i) => (
          <div
            key={`insight-search-loading-${i}`}
            className={classNames(
              styles.insightContainer,
              styles.insightContainer_Loading
            )}
          ></div>
        ))}
      </>
    );
  }

  return (
    <>
      {props.results.map((insight, i) => {
        return (
          <div
            className={styles.insightContainer}
            key={`insight-search-${insight.slug}-${i}`}
          >
            <Link href={`/insights/${insight.slug}`}>
              <a
                onClick={() => {
                  props.clearState();
                  props.closeSlider();
                }}
                data-cy={`SearchInsightsResult`}
              >
                <p className={styles.insightTitle}>{insight.title}</p>
              </a>
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default SidebarInsightResults;
