import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./Container.module.scss";

export type OpenSide =
  | "right"
  | "left"
  | "both"
  | "right-till-small"
  | "both-at-medium";

type Props = {
  openSide?: OpenSide;
  children: React.ReactNode;
};

const Container = (props: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollBarWidth = useCallback(() => {
    if (containerRef.current && document) {
      const width = window.innerWidth - document.documentElement.clientWidth;

      var root = document.querySelector(":root");
      // @ts-ignore
      root?.style?.setProperty("--scroll-width", `${width}px`);
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (containerRef.current && document) {
      scrollBarWidth();
    }
  }, [containerRef.current]);

  const overrideStyles = {
    [styles.containerOverride]:
      props.openSide !== undefined && props.openSide !== "both-at-medium",
    [styles.containerOverride_Left]: props.openSide === "left",
    [styles.containerOverride_Right]: props.openSide === "right",
    [styles.containerOverride_Both]: props.openSide === "both",
    [styles.containerOverride_RightTillSmall]:
      props.openSide === "right-till-small",
    [styles.containerOverride_BothAtMedium]:
      props.openSide === "both-at-medium",
  };
  return (
    <div className={classNames("container", overrideStyles)} ref={containerRef}>
      {props.children}
    </div>
  );
};

export default Container;
