import { GetSearchResults } from "$gql/queries/general/GetSearchResults.gen";
import {
  getImageReference,
  parseIncludesForItem,
} from "@tiicker/util/lib/contentful/helpers";
import { InsightsByLatest } from "@tiicker/util/lib/contentful/queries";
import {
  BrandDetailDocument,
  ContentfulContentType,
  ContentSection,
  InsightDocument,
  PerkDocument,
} from "@tiicker/util/lib/contentful/types";
import { Entry } from "contentful";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useContentfulEntries } from "source/contentful/provider";
import { useQueryBundle } from "source/hooks";
import styles from "./SearchSidebar.module.scss";
import SidebarBrandResults, { SearchBrandResult } from "./SidebarBrandResults";
import SidebarInsightResults, {
  SearchInsightResult,
} from "./SidebarInsightResults";
import SidebarPerkResult, { SearchPerkResult } from "./SidebarPerkResult";
import TextInput from "components/core/Forms/TextInput/TextInput";
import SidebarContainer from "components/core/SidebarContainer/SidebarContainer";
import { PerkType } from "@tiicker/util/lib/contentful/types";

type Props = {
  isOpen?: boolean;
  isMobile?: boolean;
  defaultValues: Entry<ContentSection> | undefined;
  includes?: any;
  setSearchActive: (visible: boolean) => void;
};

export type BrandSearchResult = {
  brandName: string;
  brandTicker: string;
  subBrands?: string[];
};

export type PerkSearchResult = {
  perkTitle: string;
  contentfulId: string;
  brandName?: string;
  brandTicker: string;
  perkImage?: string;
  brandLogoImage?: string;
};

const Sidebar = (props: Props) => {
  const inputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    if (props.isOpen) {
      inputRef.current?.focus();
    }
  }, [props.isOpen]);

  const [brandResults, setBrandResults] = useState<SearchBrandResult[]>([]);
  const [perkResults, setPerkResults] = useState<SearchPerkResult[]>([]);
  const [insightResults, setInsightResults] = useState<SearchInsightResult[]>(
    []
  );

  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultBrands = props.defaultValues?.fields.brands;
  const defaultPerks = props.defaultValues?.fields.perks;

  const defaultBrandIds =
    props.defaultValues && props.defaultValues.fields.perks
      ? props.defaultValues.fields.perks.map(
          (x) => (x as any).fields.brand.sys.id
        )
      : [];

  const defaultPerkBrands = useContentfulEntries<BrandDetailDocument>(
    ContentfulContentType.BrandDetail,
    {
      query: {
        content_type: ContentfulContentType.BrandDetail,
        "sys.id[in]": defaultBrandIds.join(","),
      },
    }
  );

  const defaultInsightQuery = useContentfulEntries<InsightDocument>(
    ContentfulContentType.Article,
    {
      query: {
        ...InsightsByLatest(),
        limit: "5",
      },
    }
  );

  const defaultInsights =
    defaultInsightQuery.state === "DONE"
      ? defaultInsightQuery.result.items.map((insight) => {
          return {
            slug: insight.fields.slug,
            title: insight.fields.title,
          };
        })
      : [];

  useEffect(() => {
    if (
      brandResults.length === 0 &&
      defaultBrands &&
      defaultBrands.length > 0 &&
      !hasSearched
    ) {
      setBrandResults(
        defaultBrands.map((brand) => {
          const b = brand
            ? parseIncludesForItem<BrandDetailDocument>(
                props.includes,
                brand.sys.id
              )
            : undefined;

          return {
            brandTicker: b ? b.fields.tickerSymbol : "",
            brandName: b ? b.fields.brandName : "",
            subBrands: [],
          };
        })
      );
    }
  }, [defaultBrands, brandResults, hasSearched]);

  useEffect(() => {
    if (
      perkResults.length === 0 &&
      defaultPerks &&
      defaultPerks.length > 0 &&
      defaultPerkBrands.state === "DONE" &&
      !hasSearched
    ) {
      setPerkResults(
        defaultPerks?.map((perk) => {
          const p = perk
            ? parseIncludesForItem<PerkDocument>(props.includes, perk.sys.id)
            : undefined;

          const brand = p
            ? defaultPerkBrands.result.items.find(
                (b) => b.sys.id === p.fields.brand.sys.id
              )
            : undefined;

          const perkImage = p
            ? getImageReference(p.fields.image, props.includes)
            : undefined;
          const brandImage = brand
            ? getImageReference(
                brand.fields.companyLogo,
                defaultPerkBrands.result.includes
              )
            : "";

          return {
            perkImage: perkImage ? perkImage.fields.file.url : "",
            perkTitle: p ? p.fields.title : "",
            brandLogoImage: brandImage ? brandImage.fields.file.url : "",
            brandName: brand ? brand.fields.brandName : "",
            contentfulId: p?.sys.id ?? "",
            perkType: p ? p.fields.perkType ?? "Manual" : "Manual",
          };
        })
      );
    }
  }, [
    perkResults,
    defaultPerks,
    defaultPerkBrands,
    defaultPerkBrands.state,
    hasSearched,
  ]);

  useEffect(() => {
    if (
      insightResults.length === 0 &&
      defaultInsights.length > 0 &&
      !hasSearched
    ) {
      setInsightResults(
        defaultInsights.map((insight) => {
          return {
            title: insight.title,
            slug: insight.slug,
          };
        })
      );
    }
  }, [insightResults, defaultInsights, hasSearched]);

  const queryResult = useQueryBundle(GetSearchResults, {
    skip: true,
  });

  const delayedSearch = debounce((data) => runSearch(data), 800);

  const clearState = () => {
    setBrandResults([]);
    setPerkResults([]);
    setInsightResults([]);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setIsLoading(false);
    setHasSearched(false);
  };

  const inputChange = (e) => {
    const term = e.target.value;
    delayedSearch(term);
  };

  const runSearch = useCallback(
    (searchString: string) => {
      setHasSearched(true);
      performSearch(searchString);
    },
    [hasSearched]
  );

  const performSearch = (searchString: string) => {
    const trimmedSearch = searchString.trim();

    if (trimmedSearch.length === 0) {
      clearState();
      return;
    }

    const regexStrippedArray = RegExp("[0-9a-zA-Z-. ]+").exec(trimmedSearch);

    // If the regex returns null, or the regex has trimmed the string, we know
    // it contains characters that are not allowed
    if (!regexStrippedArray || regexStrippedArray[0] !== trimmedSearch) {
      clearState();
      return;
    }

    setIsLoading(true);

    queryResult.refetch({ searchTerm: trimmedSearch }).then((result) => {
      if (!result.data) {
        return;
      }

      if (typeof window !== "undefined" && window["zaius"]) {
        window["zaius"].event("search", {
          action: "typed",
          search_term: trimmedSearch,
        });
      }

      const searchedBrandResults: {
        brandName: string;
        subBrands: string[];
        brandTicker: string | null | undefined
      }[] | undefined =
        result.data.search.iex?.map((result) => {
          return {
            brandName: result.securityName,
            subBrands: result.subBrands
              ? result.subBrands.map((x) => x.name)
              : [],
            brandTicker: result.symbol,
          };
        });
      // @ts-ignore
      setBrandResults(searchedBrandResults ?? []);

      const perkResults: SearchPerkResult[] | undefined =
        result.data.search.perks?.map((result) => {
          const perkType: PerkType = result.perkType
            ? (result.perkType as PerkType)
            : "Manual";

          return {
            perkImage: result.perkImageUrl,
            perkTitle: result.title,
            brandLogoImage: result.brandLogoUrl,
            brandName: result.brandName,
            contentfulId: result.contentfulId,
            perkType: perkType,
          };
        });
      setPerkResults(perkResults ?? []);

      const insightResults: SearchInsightResult[] | undefined =
        result.data.search.insights?.map((result) => {
          return {
            slug: result.slug,
            title: result.title,
          };
        });
      setInsightResults(insightResults ?? []);

      setIsLoading(false);
    });
  };

  return (
    <SidebarContainer
      isOpen={props.isOpen}
      onSidebarClose={props.setSearchActive}
    >
      <div className={styles.inputContainer}>
        <TextInput
          register={inputRef}
          className={styles.input}
          placeholder="Search"
          onChange={(e) => inputChange(e)}
          name="Search Input"
          data-cy="SearchSidebarTextInput"
        />
      </div>
      {brandResults.length > 0 && (
        <div className={styles.contentContainer}>
          <h5 className={styles.sectionHeading}>
            <img src="/icons/Heart.png" className={styles.icon} />
            {!hasSearched ? "Featured " : ""}Brands
          </h5>
          <SidebarBrandResults
            brands={brandResults}
            isLoading={isLoading}
            closeSlider={() => props.setSearchActive(false)}
            clearState={clearState}
          />
        </div>
      )}
      {perkResults.length > 0 && (
        <div className={styles.contentContainer}>
          <h5 className={styles.sectionHeading}>
            <img src="/icons/Gift.png" className={styles.icon} />
            {!hasSearched ? "Featured " : ""}Perks
          </h5>
          <SidebarPerkResult
            searchResults={perkResults}
            isLoading={isLoading}
            clearState={clearState}
          />
        </div>
      )}
      {insightResults.length > 0 && (
        <div className={styles.contentContainer}>
          <h5 className={styles.sectionHeading}>
            <img src="/icons/Message.png" className={styles.icon} />
            {!hasSearched ? "Latest " : ""}Insights
          </h5>
          <SidebarInsightResults
            results={insightResults}
            isLoading={isLoading}
            closeSlider={() => props.setSearchActive(false)}
            clearState={clearState}
          />
        </div>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
