import styles from "./NotificationSection.module.scss";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import classNames from "classnames";
import { AcknowledgeGlobalNotification } from "$gql/mutations/general/AcknowledgeGlobalNotification.gen";
import { UnacknowledgeGlobalNotification } from "$gql/mutations/general/UnacknowledgeGlobalNotification.gen";
import * as TimeDateIso from "@tiicker/util/lib/date-iso/time-date-iso";
import { useFetchUser, useMutationBundle } from "source/hooks";
import { useRouter } from "next/router";
import { AcknowledgeUserAlert } from "$gql/mutations/general/AcknowledgeUserAlert.gen";
import { UnacknowledgeUserAlert } from "$gql/mutations/general/UnacknowledgeUserAlert.gen";
import Button from "components/core/Button/Button";

type Props = {
  id: number;
  title: string;
  description?: string;
  link: string;
  icon: string;
  createdOn: TimeDateIso.Type;
  notificationId: number;
  notificationAcknowledgements?: { [id: string]: boolean };
  setNotificationAcknowledgements: Dispatch<
    SetStateAction<{ [id: string]: boolean }>
  >;
  notificationType: string;
  unreadNotificationCount: number;
  setUnreadNotificationCount: (count: number) => void;
  setNotificationDotVisible: (visible: boolean) => void;
};

const NotificationSection = (props: Props) => {
  const router = useRouter();
  const userBundle = useFetchUser();
  const [acknowledged, setAcknowledged] = useState(true);

  useEffect(() => {
    setAcknowledged(
      props.notificationAcknowledgements?.[props.id.toString()] || false
    );
  }, [props.notificationAcknowledgements]);

  const calculateTime = (dateTime) => {
    const now = new Date();
    const createdAt = new Date(dateTime);
    const diffInSeconds = (now.getTime() - createdAt.getTime()) / 1000;

    if (diffInSeconds < 60) {
      return "Just now";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays}d ago`;
  };

  const [acknowledgeGlobalNotification] = useMutationBundle(
    AcknowledgeGlobalNotification
  );

  const [unacknowledgeGlobalNotification] = useMutationBundle(
    UnacknowledgeGlobalNotification
  );

  const [acknowledgeUserAlert] = useMutationBundle(AcknowledgeUserAlert);
  const [unacknowledgeUserAlert] = useMutationBundle(UnacknowledgeUserAlert);

  const notificationClick = async () => {
    if (userBundle.user && props.notificationType === "globalNotification") {
      await acknowledgeGlobalNotification({
        variables: {
          userId: userBundle.user.id,
          globalNotificationId: props.notificationId,
          isRead: true,
        },
      });
    }

    if (userBundle.user && props.notificationType === "userAlert") {
      await acknowledgeUserAlert({
        variables: {
          id: props.id,
        },
      });
    }

    window.location.href = props.link;
  };

  const icon = (iconType) => {
    switch (iconType) {
      case "newPerk":
        return (
          <img src="/icons/gift.svg" className={styles.Notification__icon} />
        );
        break;
      case "newBrand":
        return (
          <img src="/icons/heart.svg" className={styles.Notification__icon} />
        );
        break;
      case "newFunctionality":
      case "emailOptIn":
        return (
          <img
            src="/icons/megaphone.svg"
            className={styles.Notification_icon}
          />
        );
        break;
      case "completeProfile":
        return (
          <img src="/icons/profile.svg" className={styles.Notification_icon} />
        );
        break;
      case "connectBrokerage":
        return (
          <img src="/icons/link.svg" className={styles.Notification_icon} />
        );
        break;
      case "syncFailure":
        return (
          <img
            src="/icons/warning-orange.svg"
            className={styles.Notification_icon}
          />
        );
        break;
    }
  };

  const displayReadIcon = () => {
    document
      .getElementById("readIcon" + props.id.toString())
      ?.classList.remove(styles.hide);
  };

  const unDisplayReadIcon = () => {
    document
      .getElementById("readIcon" + props.id.toString())
      ?.classList.add(styles.hide);
  };

  const toggleRead = () => {
    let userId = userBundle.user?.id;

    if (acknowledged) {
      if (props.notificationType === "globalNotification") {
        if (userId) {
          unacknowledgeGlobalNotification({
            variables: {
              userId: userId,
              globalNotificationId: props.notificationId,
            },
          });
        }
      } else {
        unacknowledgeUserAlert({
          variables: {
            id: props.id,
          },
        });
      }

      props.setUnreadNotificationCount(props.unreadNotificationCount + 1);
      props.setNotificationDotVisible(true);
    } else {
      if (props.notificationType === "globalNotification") {
        if (userId) {
          acknowledgeGlobalNotification({
            variables: {
              userId: userId,
              globalNotificationId: props.notificationId,
              isRead: true,
            },
          });
        }
      } else {
        acknowledgeUserAlert({
          variables: {
            id: props.id,
          },
        });
      }

      props.setUnreadNotificationCount(props.unreadNotificationCount - 1);
      props.setNotificationDotVisible(props.unreadNotificationCount - 1 > 0);
    }
    setAcknowledged(!acknowledged);
  };

  return (
    <>
      <div
        onClick={notificationClick}
        className={styles.Notification}
        onMouseOver={displayReadIcon}
        onMouseLeave={unDisplayReadIcon}
        data-cy={"Notification__" + props.icon}
      >
        <div
          className={classNames(acknowledged ? undefined : styles.unread)}
          id={props.notificationType + "_" + props.id.toString()}
          data-cy="Notification__inside"
        >
          <div className={styles.Notification__card}>
            <div className={styles.Notification__iconBox}>
              {icon(props.icon)}
            </div>
            <div className={styles.Notification__middle}>
              <div>
                <span className={styles.Notification__title}>
                  {props.title}
                </span>
                <div className={styles.Notification__description}>
                  {props.description && props.description.length > 100
                    ? props.description.substring(0, 100) + "..."
                    : props.description}
                </div>
                <div className={styles.Notification__createdOn}>
                  {calculateTime(props.createdOn)}
                </div>
              </div>
              <div
                className={classNames(
                  styles.Notification__readIcon,
                  styles.hide
                )}
                id={"readIcon" + props.id.toString()}
              >
                <Button
                  className={styles.Notification__readButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleRead();
                  }}
                >
                  {acknowledged ? (
                    <img src="/icons/unread.svg" />
                  ) : (
                    <img src="/icons/read.svg" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSection;
