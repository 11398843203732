import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import InputWrapper, { FormWrapperProps } from '../InputWrapper/InputWrapper';
import styles from './TextInput.module.scss';

type DisplayOptions = {
  size?: 'normal' | 'slim';
  inverse?: boolean;
  type?: 'number';
};

type RefReturn =
  | string
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>
  | null
  | undefined;

type Props = {
  options?: DisplayOptions;
  register: RefReturn;
  wrapperClass?: string;
  animateLabel?: boolean;
} & FormWrapperProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const TextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    wrapperClass,
    register,
    animateLabel,
    ...allOtherProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (props.value) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [props.value]);

  const handleFocusChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(e.type === 'focus');
  };

  return (
    <InputWrapper {...allOtherProps} className={wrapperClass} animateLabel={animateLabel}>
      <div className={animateLabel ? styles.container : ''}>
        <input
          {...allOtherProps}
          name={props.name}
          placeholder={animateLabel ? '' : props.placeholder}
          type={props.options?.type}
          className={classNames(styles.input, className)}
          ref={register}
          onFocus={animateLabel ? handleFocusChange : undefined}
          onBlur={animateLabel ? handleFocusChange : undefined}
        />
        {animateLabel && (
          <label
            htmlFor={props.name}
            className={classNames(styles.label, {
              [styles.label_Focused]: isFocused || props.value, // Add 'props.value' to the condition
            })}
          >
            {props.placeholder}
          </label>
        )}
      </div>
    </InputWrapper>
  );
});

export default TextInput;
