import classNames from "classnames";
import React from "react";
import styles from "./Hamburger.module.scss";

type Props = {
  isOpen?: boolean;
  onClick?(): void;
};

const Hamburger = (props: Props) => {
  return (
    <div onClick={props.onClick} className={styles.wrapper}>
      <div
        className={classNames(styles.Hamburger, {
          [styles.Hamburger_isOpen]: props.isOpen,
        })}
      >
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
      </div>
    </div>
  );
};

export default Hamburger;
