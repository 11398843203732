import Link from "next/link";
import { useState } from "react";
import styles from "./SidebarResult.module.scss";

interface Props {
  title: string;
  subTitle?: string;
  backgroundImage?: string;
  foregroundImage?: string;
  url?: string;
  sidebarOpened?: boolean;
  source?: string;
}

const SidebarInnerResult = (props: Props) => {
  const [hideImage, setHideImage] = useState<boolean>(false);

  const onImageError = () => {
    setHideImage(true);
  };

  return (
    <div className={styles.SidebarResult}>
      <div className={styles.imageContainer}>
        {props.foregroundImage && (
          <>
            <div className={styles.logoContainer}>
              {props.sidebarOpened && (
                <img
                  src={props.foregroundImage}
                  className={styles.foregroundImage}
                />
              )}
            </div>
            <div className={styles.imageTint} />
          </>
        )}
        {props.sidebarOpened && !hideImage && (
          <img
            src={props.backgroundImage || "/images/intrinio.jpg"}
            className={styles.backgroundImage}
            onError={onImageError}
          />
        )}
      </div>
      <div className={styles.textContainer}>
        <p className={styles.subTitle}>{props.subTitle}</p>
        {props.source && <p className={styles.source}>{props.source}</p>}
        <p className={styles.title}>{props.title}</p>
      </div>
    </div>
  );
};

const SidebarResult = (props: Props) => {
  const innerResult = <SidebarInnerResult {...props} />;
  return props.url ? (
    <Link href={props.url}>
      <a>{innerResult}</a>
    </Link>
  ) : (
    innerResult
  );
};

export default SidebarResult;
