import SidebarResult from "components/core/Sidebar/SidebarResult";
import styles from "./SearchSidebar.module.scss";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { PerkType } from "@tiicker/util/lib/contentful/types";

export type SearchPerkResult = {
  perkImage: string;
  perkTitle: string;
  brandLogoImage: string;
  brandName: string;
  contentfulId: string;
  perkType?: PerkType;
};

type Props = {
  searchResults: SearchPerkResult[];
  isLoading?: boolean;
  clearState: () => void;
};

const SidebarPerkResult = (props: Props) => {
  const [lastListSize, setLastListSize] = useState(2);

  useEffect(() => {
    setLastListSize(props.searchResults.length);
  }, [props.searchResults.length]);

  if (props.isLoading) {
    return (
      <>
        {Array.from({ length: lastListSize }).map((_, i) => (
          <div
            key={`perk-search-loading-${i}`}
            className={classNames(
              styles.SidebarPerkResult,
              styles.SidebarPerkResult_Loading
            )}
          ></div>
        ))}
      </>
    );
  }

  return (
    <>
      {props.searchResults.map((perk, i) => {
        if (perk.perkType === "Coming Soon") {
          return (
            <a data-cy={`SearchPerkResult`} key={`perk-${perk.contentfulId}`}>
              <div
                className={styles.SidebarPerkResult}
                key={`perk-search-${i}`}
              >
                <SidebarResult
                  backgroundImage={perk.perkImage}
                  foregroundImage={perk.brandLogoImage}
                  subTitle={perk.brandName}
                  title={perk.perkTitle}
                  sidebarOpened
                />
              </div>
            </a>
          );
        }

        return (
          <Link key={i} href={`/perks/${perk.contentfulId}`}>
            <a
              data-cy={`SearchPerkResult`}
              key={`perk-${perk.contentfulId}`}
              onClick={props.clearState}
            >
              <div
                className={styles.SidebarPerkResult}
                key={`perk-search-${i}`}
              >
                <SidebarResult
                  backgroundImage={perk.perkImage}
                  foregroundImage={perk.brandLogoImage}
                  subTitle={perk.brandName}
                  title={perk.perkTitle}
                  sidebarOpened
                />
              </div>
            </a>
          </Link>
        );
      })}
    </>
  );
};

export default SidebarPerkResult;
