import classNames from "classnames";
import React from "react";
import styles from "./OpacityCover.module.scss";

type Props = {
  visible?: boolean;
  onClick: () => void;
  closing: boolean;
};

const OpacityCover = (props: Props) => {
  return props.visible ? (
    <div
      className={classNames(
        styles.cover,
        props.closing ? styles.closing : undefined
      )}
      onClick={props.onClick}
    ></div>
  ) : (
    <></>
  );
};

export default OpacityCover;
