import classNames from "classnames";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import styles from "./SearchSidebar.module.scss";

export type SearchBrandResult = {
  brandTicker: string;
  brandName: string;
  subBrands: string[];
};

type Props = {
  brands: SearchBrandResult[];
  isLoading?: boolean;
  closeSlider(): void;
  clearState: () => void;
};

const SidebarBrandResults = (props: Props) => {
  const [lastListSize, setLastListSize] = useState(3);

  useEffect(() => {
    setLastListSize(props.brands.length);
  }, [props.brands.length]);

  if (props.isLoading) {
    return (
      <>
        {Array.from({ length: lastListSize }).map((_, i) => (
          <div
            className={classNames(
              styles.brandResult,
              styles.brandResult_Loading
            )}
            key={`brand-search-loading-${i}`}
          ></div>
        ))}
      </>
    );
  }

  return (
    <>
      {props.brands.map((brand, i) => {
        return (
          <Link key={i} href={`/brand/${brand.brandTicker}`}>
            <a
              onClick={() => {
                props.clearState();
                props.closeSlider();
              }}
              data-cy={`SearchBrandResult`}
              key={`brand-search-link-${brand.brandTicker}-${i}`}
            >
              <div className={styles.brandResult}>
                <p
                  className={styles.brandTicker}
                  data-cy="SearchBrandResultTicker"
                >
                  {brand.brandTicker}
                </p>
                <div className={styles.brandContainer}>
                  <p
                    className={styles.brandName}
                    data-cy="SearchBrandResultName"
                  >
                    {brand.brandName}
                  </p>
                  {brand.subBrands.length > 0 &&
                    brand.subBrands.map((sub) => (
                      <p className={styles.subBrand}>{sub}</p>
                    ))}
                </div>
              </div>
            </a>
          </Link>
        );
      })}
    </>
  );
};

export default SidebarBrandResults;
