import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { LinkItem } from "../NavigationBar/NavigationBar";
import styles from "./CondensedNavigation.module.scss";
import Link from "next/link";
import Button from "components/core/Button/Button";
import { useAuth, useFetchUser, useQueryBundle } from "source/hooks";
import CondensedNavHeader from "../CondensedNavHeader/CondensedNavHeader";
import Container from "components/core/Layout/Container/Container";
import { useUser } from "components/UserContext/UserContext";
import { GetReferralsAssociatedWithUser } from "$gql/queries/general/GetReferralsAssociatedWithUser.gen";
import ReferralStatus from "@tiicker/util/lib/referralStatus";
import { useFlags } from "source/hooks/useFlags";
import SmallDownArrow from "public/icons/caret-down.svg";

type Props = {
  navLinks: LinkItem[];
  setSearchActive: (visible: boolean) => void;
  notificationActive: boolean;
  setNotificationActive: (visible: boolean) => void;
  notificationDotVisible: boolean;
};

function CondensedNavigation(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const userBundle = useFetchUser();
  const userContext = useUser();
  const associatedReferralsQuery = useQueryBundle(
    GetReferralsAssociatedWithUser,
    { notifyOnNetworkStatusChange: true, fetchPolicy: "network-only" }
  );
  const [hasPendingReward, setHasPendingReward] = useState<boolean>(false);
  const flags = useFlags();
  const showReferAFriend =
    flags.referafriend186015210 &&
    (userContext.state.logins.length ||
      (userContext.state.logins.length === 0 && hasPendingReward));

    const [openDropdown, setOpenDropdown] = useState<string | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null); 


  useEffect(() => {
    if (
      associatedReferralsQuery.state === "DONE" &&
      associatedReferralsQuery.data.getReferralsAssociatedWithUser.length
    ) {
      const pendingRewards =
        associatedReferralsQuery.data.getReferralsAssociatedWithUser.find(
          (referral) => referral.status === ReferralStatus.PENDING
        );

      setHasPendingReward(!!pendingRewards);
    }
  }, [associatedReferralsQuery]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      body.style.overflowY = isOpen ? "hidden" : "scroll";
      body.style.position = isOpen ? "fixed" : "static";
    }
  }, [isOpen]);

  const toggleDropdown = (text: string) => {
    setOpenDropdown(openDropdown === text ? null : text);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdown(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames({ [styles.CondensedNavHeader__navIsOpen]: isOpen })}
    >
      <div className={styles.CondensedNav} >
        <CondensedNavHeader
          isOpen={false}
          onSearchClick={() => props.setSearchActive(true)}
          onHamburgerClick={() => setIsOpen(true)}
          notificationActive={props.notificationActive}
          setNotificationActive={props.setNotificationActive}
          notificationDotVisible={props.notificationDotVisible}
        />
        <div
          className={classNames(styles.CondensedNav__mobileMenu, {
            [styles.CondensedNav__mobileMenu__Open]: isOpen,
          })}
        >
          <div className={styles.CondensedNav__menuNavWrapper}>
            <Container>
              <CondensedNavHeader
                isOpen={true}
                onSearchClick={() => props.setSearchActive(true)}
                onClose={() => setIsOpen(false)}
                notificationActive={props.notificationActive}
                setNotificationActive={props.setNotificationActive}
                notificationDotVisible={props.notificationDotVisible}
                inverted
              />
            </Container>
          </div>
          <div className={styles.CondensedNav__linkList}>
            {userBundle.user && (
              <>
                <Link href="/dashboard">
                  <a className={styles.CondensedNav__navLink}>
                    <span>Dashboard</span>
                  </a>
                </Link>
                <Link href="/my-account">
                  <a className={styles.CondensedNav__navLink}>
                    <span>My Account</span>
                  </a>
                </Link>
                {showReferAFriend && (
                  <Link href="/refer-a-friend">
                    <a className={styles.CondensedNav__navLink}>
                      <span>Refer Friends</span>
                    </a>
                  </Link>
                )}
              </>
            )}

            {/* {props.navLinks.map((link) => (
              <Link
                key={`condensed-nav-${link.href}-${link.text}`}
                href={link.href}
              >
                <a className={styles.navLink}>
                  <span>{link.text}</span>
                </a>
              </Link>
            ))} */}

              {props.navLinks.map((link) => (
                  <React.Fragment key={`mobile-nav-${link.href}-${link.text}`}>
                    {link.children ? (
                      <>
                        <div
                          onClick={() => toggleDropdown(link.text)}
                          className={styles.CondensedNav__navLink}
                          data-cy={`NavLink-${link.href.split("/")[1]}`}
                        >
                          <span>{link.text}</span>
                          <SmallDownArrow className={`${styles.CondensedNav__dropdownArrow} ${openDropdown === link.text ? styles.CondensedNav__dropdownArrow_open : ''}`} />
                        </div>
                        <div className={`${styles.CondensedNav__dropdown} ${openDropdown === link.text ? styles.CondensedNav__dropdown_open : ''}`}>
                        {link.children.map((childLink) => (
                          <Link key={childLink.href} href={childLink.href}>
                            <a className={styles.CondensedNav__dropdownItem}>
                              {childLink.img && (
                                <img 
                                  src={childLink.img} 
                                  alt={childLink.text} 
                                  className={styles.CondensedNav__dropdownItemImage}
                                />
                              )}
                              <span>{childLink.text}</span>
                            </a>
                          </Link>
                        ))}
                      </div>
                      </>
                    ) : (
                      <Link href={link.href}>
                        <a className={styles.CondensedNav__navLink} data-cy={`NavLink-${link.href.split("/")[1]}`}>
                          <span>{link.text}</span>
                        </a>
                      </Link>
                    )}
                  </React.Fragment>
                ))}

            {!userBundle.user ? (
              <a onClick={() => auth.login()} className={styles.CondensedNav__navLink}>
                <span>Sign In</span>
              </a>
            ) : (
              <>
                <a onClick={() => auth.logout()} className={styles.CondensedNav__navLink}>
                  <span>Sign Out</span>
                </a>
              </>
            )}
          </div>
          <div className={styles.CondensedNav__lowerMenuWrapper}>
            {!userBundle.user ? (
              <div>
                <Button
                  onClick={() => auth.smsSignUp()}
                  className={styles.CondensedNav__joinNow}
                >
                  Join Now
                </Button>
                <div className={styles.CondensedNav__appPillContainer}>
                  <Link href={"https://tiicker.app.link/app"}>
                    <a className={styles.CondensedNav__appPillLink}>
                      <div className={styles.CondensedNav__appIcons}>
                        <img src="/images/apple-icon.png" alt="apple icon" />
                        <img src="/images/android-icon.png" alt="android icon" />
                      </div>
                      <span>Download the App</span>
                    </a>
                  </Link>
                </div>
              </div>
              
            ) : (
              <>
                <Link href={"https://tiicker.app.link/app"}>
                  <a className={styles.CondensedNav__appPillLink}>
                    <div className={styles.CondensedNav__appIcons}>
                      <img src="/images/apple-icon.png" alt="apple icon" />
                      <img src="/images/android-icon.png" alt="android icon" />
                    </div>
                    <span>Download the App</span>
                  </a>
                </Link>
              </>
            )}
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default CondensedNavigation;
